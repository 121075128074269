import styled from "styled-components"

export const Button = styled.button`
  background: linear-gradient(
    270deg,
    rgba(27, 165, 131, 1) 0%,
    rgba(0, 128, 128, 1) 100%
  );
  border: 0;
  outline: 0;
  color: white;
  border-radius: 4px;
  padding: 0.2rem 0.4rem;
  cursor: pointer;
  &:hover {
    background-color: #008080;
    transform: translateY(-1px);
    box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
  }
`

export const Anchor = styled.a`
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
  color: #008080;
  &:hover {
    font-weight: bolder;
  }
`
export const Headline2 = styled.h2`
  text-align: center;
  margin: 0 auto 1rem auto;
  width: 33%;
  border-bottom: 0.2rem solid #008080;
  padding: 0.2rem 0;
  @media (max-width: 800px) {
    width: 90%;
    margin: 0.6rem auto;
  }
`
export const Banner = styled.div<{ width?: number }>`
  position: absolute;
  top: 4rem;
  left: 0.5rem;
  width: 30%;
  font-size: 0.8rem;
  color: white;
  border: 2px solid white;
  border-radius: 5px;
  padding: 0.2rem;
  z-index: 98;
  p {
    line-height: 1.5;
    margin: 0;
    a {
      color: white;
      font-weight: bolder;
    }
  }

  @media (max-width: 800px) {
    top: 0.6rem;
    right: 0.2rem;
    left: auto;
    width: 70%;
    font-size: 0.5rem;
    border: none;
    padding: 0;
  }
`
