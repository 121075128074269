import React, { useState } from "react"
import { Helmet } from "react-helmet"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Anchor, Button } from "../style/generic"

const Container = styled.div`
  font-size: 0.8rem;
  margin: 4rem auto auto auto;
  width: 90%;
  @media (min-width: 1000px) {
    width: 50%;
  }
  .category {
    margin: 1rem 0 0 0;
  }
  p {
    span {
      background-color: hsla(0, 0%, 0%, 0.04);
      border-radius: 3px;
      font-family: "SFMono-Regular", Consolas, "Roboto Mono", "Droid Sans Mono",
        "Liberation Mono", Menlo, Courier, monospace;
      padding: 0;
      padding-top: 0.2em;
      padding-bottom: 0.2em;
    }
  }
  h1 {
    margin: 0 0 2rem 0;
  }
  h3 {
    font-weight: 400;
    font-size: 1.25rem;
    color: #008080;
    margin: 0;
  }
  .description {
    margin: 2rem 0;
    h2 {
      font-weight: 400;
      font-size: 1.25rem;
      color: #008080;
      margin: 0;
      text-align: center;
    }
    p {
      margin: 0.5rem 0;
    }
    .tips {
      h3 {
        font-size: 1.1rem;
      }
      li {
        margin: auto;
        @media (max-width: 768px) {
          width: 80%;
        }
      }
    }
  }
  .muscles {
    @media (min-width: 1000px) {
      font-size: 1rem;
      margin: auto;
      ul {
        width: 50%;
        margin: 0 0.5rem;
      }
    }
    display: flex;
    margin: 2rem 0;
    font-size: 0.6rem;
    text-align: center;
    ul {
      list-style: none;
      li {
        margin: 0;
      }
    }
    h3 {
      color: #000;
      border-bottom: 2px solid #008080;
    }
  }
  .license {
    width: 4rem;
    margin: auto;
    Button {
      a {
        color: white;
      }
    }
  }
  img {
    margin: 2rem 0 0 0;
  }
  ul {
    margin: 0;
    h2 {
      margin: 0.5rem 0;
    }
    li {
      margin: auto;
    }
  }
`
const ToggledList = styled.ul<{ toggled?: boolean }>`
  position: relative;
  text-align: left !important;
  list-style: none;
  margin: 0;
  border-top: 2px solid #008080;
  border-bottom: 2px solid #008080;
  h2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0.5rem 0;
    color: #008080;
    font-size: 1.4rem;
    cursor: pointer;
    span {
      font-size: 0.9rem;
      font-weight: 300;
      vertical-align: middle;
    }
  }
  li {
    display: ${props => (props.toggled ? "block" : "none")};
    margin: auto;
    li::before {
      content: " ";
      position: absolute;
      width: 8px;
      height: 8px;
      top: 11px;
      left: -20px;
      border-radius: 50%;
      background-color: #008080;
    }
  }
`

//@ts-ignore
export default function Template({ pageContext: { exercise } }) {
  const [toggleList, toggle] = useState(true)
  return (
    <Layout>
      <SEO title={exercise.name} />
      <Helmet>
        <script type="application/ld+json">
          {`        
            {
              "@context": "https://schema.org",
              "@type": "Article",
              "url": "https://www.getfitnessfluent/exercises/${exercise.name}/",
              "headline": "How To Do ${exercise.name}",
              "image": "${exercise.images[0] ? exercise.images[0].image : ``}",
              "description": "${exercise.description}"
            }
        `}
        </script>
      </Helmet>
      <Container>
        <span>
          <Anchor href="/exercises/">Back</Anchor>
        </span>
        <p className="category">
          Category: <span>{exercise.category.name}</span>
        </p>
        <h1>{exercise.name}</h1>
        <ToggledList toggled={toggleList}>
          <h2 onClick={() => toggle(!toggleList)}>
            Muscles: <span>{toggleList ? "close" : "open"}</span>
          </h2>
          {exercise.muscles.map((muscle: any) => (
            <li key={`muscle-${muscle.id}`}>{muscle.name}</li>
          ))}
        </ToggledList>
        {exercise.images.length > 0 && (
          <img
            src={exercise.images[0].image}
            alt={`image of a ${exercise.name} exercise`}
          />
        )}
        <div className="description">
          <h2>How To Do {exercise.name}</h2>
          <div dangerouslySetInnerHTML={{ __html: exercise.description }} />
          {exercise.comments.length > 0 && (
            <ul className="tips">
              <h3>Additional Tips</h3>
              {exercise.comments.map((comment: any) => (
                <li key={`comment-${comment.id}`}>{comment.comment}</li>
              ))}
            </ul>
          )}
        </div>
        <div className="muscles">
          <ul>
            <h3>Primary Muscles</h3>
            {exercise.muscles.map((muscle: any) => (
              <li key={`muscle-${muscle.id}`}>{muscle.name}</li>
            ))}
          </ul>
          <ul>
            <h3>Seconday Muscles</h3>
            {exercise.muscles_secondary.map((muscle: any) => (
              <li key={`muscle-secondary-${muscle.id}`}>{muscle.name}</li>
            ))}
          </ul>
        </div>
        <span>
          <Anchor href="/exercises/">Back</Anchor>
        </span>
        <div className="license">
          <Button>
            <a target="_blank" href={exercise.license.url} rel="nofollow">
              License
            </a>
          </Button>
        </div>
      </Container>
    </Layout>
  )
}
